import React from 'react';

import Base from '../components/Base';
import SEO from '../components/SEO';
import LocationSection from '../components/sections/LocationSection';
import CTASection from '../components/sections/CTASection';
import BannerSection from '../components/sections/BannerSection';
import AboutSection from '../components/sections/AboutSection';

const NotFoundPage = () => (
  <Base>
    <SEO title="404: Not found" />
    <BannerSection
      title="NOT FOUND"
      subtitle="You just hit a route that doesn&#39;t exist... the sadness."
    />
    <LocationSection />
    <CTASection
      dark
      title="Want to learn more? Check out some of our past work."
      ctaText="View Case Studies"
      ctaTo="/work"
    />
    <AboutSection />
  </Base>
);

export default NotFoundPage;
